var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-template-table-container"},[_c('CCard',{staticClass:"email-template-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.listEmail,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.no))])]}},{key:"display_name",fn:function(ref){
var item = ref.item;
return [_c('td',{class:(item.option_name || item.is_add_button)?'email-template-child':''},[_vm._v(" "+_vm._s((item.option_name) || item.display_name)+" "),(item.is_add_button)?_c('CButton',{on:{"click":function($event){return _vm.addEmailTemplate(item.group_name)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" "+_vm._s(_vm.$t('LIST_EMAIL_TEMPLATE_TABLE_COMPONENT_BUTTON_ADD_NEW_EMAIL'))+" ")],1):_vm._e()],1)]}},{key:"subject",fn:function(ref){
var item = ref.item;
return [(!item.is_add_button)?_c('td',[_vm._v(_vm._s((item.option_name || !item.group_name)?item.subject:'-'))]):_c('td')]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"buttons"},[(!item.is_add_button)?_c('div',[(_vm.isAllowedUpdating)?_c('CButton',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e(),(_vm.isAllowedDuplicate && item.option_name)?_c('CButton',{on:{"click":function($event){return _vm.duplicate(item)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1):_vm._e(),(_vm.isAllowedDelete && item.option_name)?_c('CButton',{on:{"click":function($event){return _vm.openModal(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-del-user.svg"),"alt":""}})]):_vm._e()],1):_vm._e()])]}}])})],1),_c('CModal',{attrs:{"id":"deleteModal","show":_vm.warningModal,"centered":true},on:{"update:show":function($event){_vm.warningModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CButtonClose',{on:{"click":function($event){_vm.warningModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"cancel-btn",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){_vm.warningModal = false}}},[_vm._v(_vm._s(_vm.$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_BUTTON_CANCEL')))]),_c('CButton',{staticClass:"delete-btn",on:{"click":function($event){return _vm.del(_vm.name)}}},[_vm._v(_vm._s(_vm.$t('DELETE_EMAIL_TEMPLATE_MODAL_BUTTON_DELETE')))])]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"pb-4 tit-dele"},[_c('p',[_vm._v(_vm._s(_vm.$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_TITLE')))])]),_c('div',{staticClass:"text-modal",domProps:{"innerHTML":_vm._s(_vm.$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_CONTENT', {name: _vm.nameDelete}))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }