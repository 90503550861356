<template>
  <div class="email-template-content">
    <CCard>
      <CCardHeader>
        <h1>{{$t('LIST_EMAIL_TEMPLATE_PAGE_LABEL_TITLE')}}</h1>
      </CCardHeader>
      <CCardBody>
        <ListEmailTemplateTable
          :listEmail="listEmail"
          :limit="limit"
          :activePage="activePage"
          :total="total"
          :getEmailTemplates="getEmailTemplates"
          :isAllowedDuplicate="isAllowedDuplicate"
          :isAllowedDelete="isAllowedDelete"
          :isAllowedUpdating="isAllowedUpdating"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { endpoints, env } from '@/constants';
import responseHelper from '@/utils/response-helper';
import ListEmailTemplateTable from '@/components/EmailTemplate/ListEmailTemplateTable';
import StakingPagination from '@/components/StakingPagination';

export default {
  name: 'Email',
  components: {
    StakingPagination,
    ListEmailTemplateTable,
  },
  data() {
    return {
      listEmail: [],
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      putActivePage: 1,
      isAllowedDuplicate: false,
      isAllowedDelete: false,
      isAllowedUpdating: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    this.getEmailTemplates();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'CREATE_EMAIL_TEMPLATE') {
            this.isAllowedDuplicate = true;
            return;
          }

          if (item.name === 'DELETE_EMAIL_TEMPLATE') {
            this.isAllowedDelete = true;
            return;
          }

          if (item.name === 'UPDATE_EMAIL_TEMPLATE') {
            this.isAllowedUpdating = true;
            return;
          }
        });
      }
    },
    async getEmailTemplates() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getEmailTemplate, { params });
        this.listEmail = result.data.items;

        if (!this.isAllowedDuplicate) {
          this.listEmail = this.listEmail.filter(item => !item.is_add_button);
        }

        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;
      this.getEmailTemplates();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;
      this.getEmailTemplates();
    },
  },
};
</script>
<style lang="scss">
.email-template-content {
  padding: 0 15px;
  .card-header {
    border: none;
    padding-bottom: 30px;
    h1 {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }
  }
  .btn-create {
    background: #3b4b7e;
    border: 0;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    margin-bottom: 20px;
    color: #fff;
    padding: 0 30px;
    height: 37px;
  }
}
</style>
