<template>
  <div class="email-template-table-container">
    <CCard class="email-template-list-table-card">
      <CDataTable :items="listEmail" :fields="fields" fixed hover striped bordered>
        <template #no="{item}">
          <td>{{item.no}}</td>
        </template>
        <template #display_name="{item}">
          <td :class="(item.option_name || item.is_add_button)?'email-template-child':''">
            {{(item.option_name) || item.display_name}}
            <CButton v-if="item.is_add_button" @click="addEmailTemplate(item.group_name)">
              <CIcon name="cil-plus" />
              {{$t('LIST_EMAIL_TEMPLATE_TABLE_COMPONENT_BUTTON_ADD_NEW_EMAIL')}}
            </CButton>
          </td>
        </template>
        <template #subject="{item}">
          <td v-if="!item.is_add_button">{{(item.option_name || !item.group_name)?item.subject:'-'}}</td>
          <td v-else></td>
        </template>
        <template #action="{item}">
          <td class="buttons">
            <div v-if="!item.is_add_button">
              <CButton @click="editItem(item)" v-if="isAllowedUpdating">
                <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
              </CButton>
              <CButton @click="duplicate(item)" v-if="isAllowedDuplicate && item.option_name">
                <CIcon name="cil-copy" />
              </CButton>
              <CButton @click="openModal(item)" v-if="isAllowedDelete && item.option_name">
                <img src="@/assets/images/ico-del-user.svg" alt class="hand edit-icon" />
              </CButton>
            </div>
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class>
        <div class="pb-4 tit-dele">
          <p>{{$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_TITLE')}}</p>
        </div>
        <div
          class="text-modal"
          v-html="$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_CONTENT', {name: nameDelete})"
        ></div>
      </div>
      <template #footer>
        <CButton
          class="cancel-btn"
          @click="warningModal = false"
          color="dark"
          variant="outline"
        >{{$t('DELETE_OPTION_EMAIL_TEMPLATE_MODAL_BUTTON_CANCEL')}}</CButton>
        <CButton
          @click="del(name)"
          class="delete-btn"
        >{{$t('DELETE_EMAIL_TEMPLATE_MODAL_BUTTON_DELETE')}}</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Constants } from '@/constants';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ListEmailTemplateTable',
  data() {
    return {
      fields: [
        {
          key: 'no',
          label: 'No.',
          _style: 'min-width: 50px;',
        },
        {
          key: 'display_name',
          label: this.$t('LIST_EMAIL_TEMPLATE_TABLE_LABEL_EMAIL'),
          _style: 'min-width: 315px;',
        },
        {
          key: 'subject',
          label: this.$t('LIST_EMAIL_TEMPLATE_TABLE_LABEL_SUBJECT_LINE'),
          _style: 'min-width: 400px;',
        },
        {
          key: 'action',
          label: '',
        },
      ],
      warningModal: false,
      nameDelete: '',
      name: '',
    };
  },
  props: {
    getEmailTemplates: {
      type: Function,
      default: null,
    },
    listEmail: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    isAllowedDuplicate: {
      type: Boolean,
      default: false,
    },
    isAllowedDelete: {
      type: Boolean,
      default: false,
    },
    isAllowedUpdating: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {},
  methods: {
    editItem(item) {
      if (!item.option_name) {
        this.$router.push(`email-templates/${item.name}/details`);
      } else {
        this.$router.push(`email-templates/${item.name}/email-tempalte-option-details`);
      }
    },
    async duplicate(value) {
      try {
        const name = value.name;
        await this.$http.post(endpoints.duplicateTemplate(name));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DUPLICATE_EMAIL_TEMPLATE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('DUPLICATE_EMAIL_TEMPLATE_NOTIFY_TEXT_SUCCESSFUL'),
        });
        this.getEmailTemplates();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DUPLICATE_EMAIL_TEMPLATE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async del(value) {
      try {
        await this.$http.delete(endpoints.deleteTemplate(value));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DUPLICATE_EMAIL_TEMPLATE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('DUPLICATE_EMAIL_TEMPLATE_NOTIFY_MESSAGE_DELETE_SUCCESSFUL'),
        });
        this.warningModal = false;
        this.getEmailTemplates();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    openModal(value) {
      this.nameDelete = value.option_name;
      this.name = value.name;
      this.warningModal = true;
    },
    addEmailTemplate(name) {
      this.$router.push(`email-templates/${name}/create`);
    },
  },
};
</script>
<style lang="scss">
.email-template-table-container {
  .email-template-list-table-card {
    button {
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      background: none;
      padding-left: 0;
      img {
        width: 18px;
        height: auto;
      }
      svg {
        width: 17px !important;
        height: 17px !important;
        font-size: 17px !important;
      }
    }
    .duplicate-icon {
      width: 18px;
      height: auto;
    }
    table {
      margin-bottom: 0;
      th {
        padding-bottom: 10px;
        padding-top: 10px;
      }
      td {
        padding-bottom: 4px;
        padding-top: 4px;
        vertical-align: middle;
        color: #4d5666;
        font-size: 14px;
        font-weight: 400;
        &.email-template-child {
          padding-left: 40px;
          button {
            color: #3b4b7e;
            font-size: 14px;
            font-weight: 500;
            svg {
              border: 1px solid #3b4b7e;
              color: #3b4b7e;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              margin: 0 2px 0 0;
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }

  .modal-body {
    padding: 1.3rem 1.5rem;
    .tit-dele {
      p {
        font-size: 18px;
      }
    }
  }

  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
